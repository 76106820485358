<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" label="LBLDEPT" name="deptCd" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="지정년도"
            type="year"
            name="year"
            default="today"
            v-model="searchParam.year"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="table"
          title="유소견자 사후관리 대상 목록"
          tableId="table"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :merge="grid.merge"
          :columnSetting="false"
          :checkClickFlag="false"
          selection="multiple"
          rowKey="suspectId"
          @tableDataChange="tableDataChange"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- <c-btn label="LBLADD" icon="add" @btnClicked="addSuspect" /> -->
              <c-btn v-if="editable && grid.data.length > 0" label="LBLREMOVE" icon="remove" @btnClicked="removeSuspect" />
              <c-btn
                v-if="editable && grid.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="grid.data.filter(item => item.editFlag === 'U')"
                :mappingType="mappingType"
                label="해제일 저장"
                icon="save"
                @beforeAction="saveSuspect"
                @btnCallback="saveCallback" />
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
            <template>
              <q-chip
                v-if="props.row.editFlag !== 'C'"
                outline square
                color="blue"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click.stop="rowClick(props.row)">
                ▶
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="table2"
          title="유소견자별 건강상담 목록"
          tableId="table2"
          :columns="grid2.columns"
          :gridHeight="grid2.height"
          :data="grid2.data"
          :columnSetting="false"
          :filtering="false"
          @linkClick="detailPopup"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && isRegister" label="LBLREG" icon="add" @btnClicked="detailPopup" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'suspect-user',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'userId', },
          { index: 1, colName: 'userId', },
          { index: 2, colName: 'userId', },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'judgmentNames',
            field: 'judgmentNames',
            label: '판정(질환)',
            align: 'left',
            sortable: false,
          },
          {
            name: 'appointDate',
            field: 'appointDate',
            label: '지정일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'releaseDate',
            field: 'releaseDate',
            label: '해제일',
            align: 'center',
            type: 'date',
            style: 'width:140px',
            sortable: false,
          },
          {
            name: 'releaseRemark',
            field: 'releaseRemark',
            label: '해제비고',
            align: 'left',
            type: 'textarea',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '상담',
            align: 'center',
            style: 'width:60px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'consultTypeName',
            field: 'consultTypeName',
            label: '상담구분',
            style: 'width: 100px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'consultDate',
            field: 'consultDate',
            label: '상담일',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'consultContent',
            field: 'consultContent',
            label: '상담내용',
            align: 'left',
            style: 'width: 300px',
            sortable: false,
          },
        ],
        data: [],
      },
      period: [],
      searchParam: {
        plantCd: '',
        deptCd: '',
        year: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      saveUrl: '',
      isSave: false,
      mappingType: 'POST',
      listConsultUrl: '',
      deleteUrl: '',
      row: {
        suspectId: '',
        deptName: '',
        userName: '',
      },
      isRegister: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.suspect.user.list.url;
      this.saveUrl = transactionConfig.hea.checkup.suspect.user.save.url;
      this.deleteUrl = transactionConfig.hea.checkup.suspect.user.delete.url;

      this.listConsultUrl = selectConfig.hea.checkup.suspect.consult.list.url;

      // this.searchParam.year = this.$comm.getThisYear();
      this.getList();
    },
    getList() {
      this.grid2.data = [];
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = this.$_.map(_result.data, item => {
          return this.$_.extend(item, {
            releaseDateSave: item.releaseDate,
            releaseClear: false,
          })
        });
        this.isRegister = false;
      },);
    },
    rowClick(item) {
      this.row.suspectId = item.suspectId;
      this.row.deptName = item.deptName;
      this.row.userName = item.userName;

      this.isRegister = true;
      this.$http.url = this.listConsultUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        suspectId: item.suspectId
      };
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);
    },
    tableDataChange(props, col) {
      if (col.name === 'releaseDate') {
        if (props.row.releaseDateSave 
          && !props.row.releaseDate) {
          /**
           * 해제일이 저장되어져 있지만, 지우는 경우
           */
          this.$set(props.row, 'releaseClear', true)
        } else if (props.row.releaseDate && props.row.releaseClear) {
          this.$set(props.row, 'releaseClear', false)
        }
      }
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./consultDetail.vue"}`);
      this.popupOptions.title = this.row.userName + '의 건강상담 상세';
      this.popupOptions.isFull = false;
      this.popupOptions.width = '95%';
      this.popupOptions.param = {
        suspectConsultId: result ? result.suspectConsultId : '',
        suspectId: this.row.suspectId,
        suspectFlag: 'Y',
        suspectUserName: this.row.deptName + ' / ' +  this.row.userName,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDetailPopup;
    },
    closeDetailPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.rowClick(this.row);
    },
    addSuspect() {
      this.popupOptions.target = () => import(`${"./suspectUserPop.vue"}`);
      this.popupOptions.title = '유소견자 대상 지정';
      this.popupOptions.param = {}
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSuspectPopup;
    },
    closeSuspectPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    saveSuspect() {
      let vaildPass = true;
      let releaseFilter = this.$_.filter(this.grid.data, item => {
        return !item.releaseDate
      })
      let blankUser = [];
      if (releaseFilter && releaseFilter.length > 0) {
        let users = this.$_.map(this.$_.pickBy(this.$_.countBy(releaseFilter, 'userId'), item => {
          return item > 1
        }), (value, key) => {
          return {
            userId: key,
          }
        })
        if (!this.$_.isEmpty(users)) {
          vaildPass = false;
          blankUser = this.$_.map(users, item => {
            return this.$_.find(this.grid.data, { userId: item.userId }).userName
          })
        }
      }

      if (vaildPass) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: `동일 사람에게 유소견자 해제일이\n두 개 이상 비어있을 수 없습니다.\n[${blankUser.join(',')}] `, // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    removeSuspect() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$set(this.$refs['table'], 'selected', [])
              this.getList();
            })
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
