var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-dept", {
                  attrs: { type: "search", label: "LBLDEPT", name: "deptCd" },
                  model: {
                    value: _vm.searchParam.deptCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "deptCd", $$v)
                    },
                    expression: "searchParam.deptCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    label: "지정년도",
                    type: "year",
                    name: "year",
                    default: "today",
                  },
                  model: {
                    value: _vm.searchParam.year,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "year", $$v)
                    },
                    expression: "searchParam.year",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "유소견자 사후관리 대상 목록",
                  tableId: "table",
                  columns: _vm.grid.columns,
                  gridHeight: _vm.grid.height,
                  data: _vm.grid.data,
                  merge: _vm.grid.merge,
                  columnSetting: false,
                  checkClickFlag: false,
                  selection: "multiple",
                  rowKey: "suspectId",
                },
                on: { tableDataChange: _vm.tableDataChange },
                scopedSlots: _vm._u([
                  {
                    key: "customArea",
                    fn: function ({ props }) {
                      return [
                        [
                          props.row.editFlag !== "C"
                            ? _c(
                                "q-chip",
                                {
                                  staticStyle: {
                                    "margin-bottom": "4px !important",
                                  },
                                  attrs: {
                                    outline: "",
                                    square: "",
                                    color: "blue",
                                    clickable: true,
                                    "text-color": "white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.rowClick(props.row)
                                    },
                                  },
                                },
                                [_vm._v(" ▶ ")]
                              )
                            : _vm._e(),
                        ],
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && _vm.grid.data.length > 0
                          ? _c("c-btn", {
                              attrs: { label: "LBLREMOVE", icon: "remove" },
                              on: { btnClicked: _vm.removeSuspect },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.grid.data.length > 0
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.saveUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.grid.data.filter(
                                  (item) => item.editFlag === "U"
                                ),
                                mappingType: _vm.mappingType,
                                label: "해제일 저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveSuspect,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                        _c("c-btn", {
                          attrs: { label: "LBLSEARCH", icon: "search" },
                          on: { btnClicked: _vm.getList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
          [
            _c(
              "c-table",
              {
                ref: "table2",
                attrs: {
                  title: "유소견자별 건강상담 목록",
                  tableId: "table2",
                  columns: _vm.grid2.columns,
                  gridHeight: _vm.grid2.height,
                  data: _vm.grid2.data,
                  columnSetting: false,
                  filtering: false,
                },
                on: { linkClick: _vm.detailPopup },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && _vm.isRegister
                          ? _c("c-btn", {
                              attrs: { label: "LBLREG", icon: "add" },
                              on: { btnClicked: _vm.detailPopup },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }